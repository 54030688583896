import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  Redirect,
} from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";

import Header from "./fragments/common/Header";
import Footer from "./fragments/common/Footer";
import Home from "./pages/Home";
import ShopsRetailSigns from "./pages/signselection/ShopsRetailSigns";

// About Us
import OurStory from "./pages/aboutus/OurStory";
import MeetTheTeam from "./pages/aboutus/MeetTheTeam";
import ContactUs from "./pages/aboutus/ContactUs";

// What We Do
import SignageSolution from "./pages/whatwedo/SignageSolution";
import CaseStudies from "./pages/whatwedo/CaseStudies";
import News from "./pages/whatwedo/News";
import SinglePost from "./pages/whatwedo/SinglePost";

// Top level pages
import QuoteSiteVisit from "./pages/QuoteSiteVisit";
import SignBuyersGuide from "./pages/SignBuyersGuide";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Locations from "./pages/Locations";
import LocationSingle from "./pages/LocationSingle";
import ProductSingle from "./pages/ProductSingle";

import locations from "./data/locations";
import locationsGallery from "./data/locationsGallery";

const App = () => {
  const [posts, setPosts] = useState([]);
  const location = useLocation();

  useEffect(() => {
    // Fetch posts from WP
    axios
      .get(`https://blog.yellowsigns.co.uk/wp-json/wp/v2/posts?per_page=30`)
      .then((res) => {
        setPosts(res.data);
      })
      .catch((error) => console.error("Error fetching posts:", error));

    // LiveChat script setup
    const livechatScript = document.createElement("script");
    livechatScript.innerHTML = `window.__lc = window.__lc || {};
      window.__lc.license = 13787559;
      (function (n, t, c) {
        function i(n) {
          return e._h ? e._h.apply(null, n) : e._q.push(n);
        }
        var e = {
          _q: [],
          _h: null,
          _v: "2.0",
          on: function () {
            i(["on", c.call(arguments)]);
          },
          once: function () {
            i(["once", c.call(arguments)]);
          },
          off: function () {
            i(["off", c.call(arguments)]);
          },
          get: function () {
            if (!e._h)
              throw new Error("[LiveChatWidget] You can't use getters before load.");
            return i(["get", c.call(arguments)]);
          },
          call: function () {
            i(["call", c.call(arguments)]);
          },
          init: function () {
            var n = t.createElement("script");
            n.async = !0,
              n.type = "text/javascript",
              n.src = "https://cdn.livechatinc.com/tracking.js",
              t.head.appendChild(n);
          },
        };
        !n.__lc.asyncInit && e.init(),
          (n.LiveChatWidget = n.LiveChatWidget || e);
      })(window, document, [].slice);`;
    setTimeout(() => {
      document.body.appendChild(livechatScript);
    }, 2000);
  }, []);

  return (
    <>
      <Helmet>{/* <meta name="robots" content="nofollow,noindex" /> */}</Helmet>

      <Router>
        <Header />
        <Switch>
          {locationsGallery.map((page, index) => (
            <Route exact path={page?.href} key={index}>
              <ProductSingle page={page} />
            </Route>
          ))}

          {/* About us */}
          <Route exact path="/our-story" component={OurStory} />
          <Route exact path="/meet-the-team" component={MeetTheTeam} />
          <Route exact path="/contact-us" component={ContactUs} />

          {/* What We Do */}
          <Route exact path="/signage-solution" component={SignageSolution} />
          <Route exact path="/case-studies">
            <CaseStudies slug="/case-studies" posts={posts} />
          </Route>
          <Route exact path="/news">
            <News slug="/news" posts={posts} />
          </Route>

          {/* Blog Pages Single */}
          {posts.map((item, index) => (
            <Route path={`/${item.slug}`} key={index}>
              <SinglePost
                slug={item.slug}
                title={item.title.rendered}
                date={item.date}
                img={item.jetpack_featured_media_url}
                content={item.content.rendered}
                category={item.categories[0] === 2 ? "News" : "Case Studies"}
                recentPosts={posts}
              />
            </Route>
          ))}

          {/* Location Pages Single */}
          <Route exact path="/locations" component={Locations} />
          {locations.map((item, index) => (
            <Route path={item.href} key={index}>
              <LocationSingle {...item} />
            </Route>
          ))}

          {/* Home & Top Level Pages */}
          <Route exact path="/sign-buyers-guide" component={SignBuyersGuide} />
          <Route exact path="/quote-site-visit" component={QuoteSiteVisit} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsConditions}
          />
          <Route exact path="/" component={Home} />

          {/* Old Route Need to be redirected */}
          <Route exact path="/contact">
            <Redirect to="/contact-us" />
          </Route>

          <Route exact path="/basket">
            <Redirect to="/" />
          </Route>

          <Route exact path="/products">
            <Redirect to="/" />
          </Route>

          <Route exact path="/cut-vinyl-">
            <Redirect to="/cut-vinyl-graphics" />
          </Route>

          <Route exact path="/materials">
            <Redirect to="/" />
          </Route>

          <Route
            exact
            path="/digital-wallpaper-restaurant-and-takeaway-tinseltown"
          >
            <Redirect to="/case-studies" />
          </Route>

          <Route exact path="/digital-wallpaper-restaurant">
            <Redirect to="/digital-wallpaper" />
          </Route>
          {/* Fallback */}
          <Route path="*">
            <div style={{ padding: "10rem", textAlign: "center" }}>
              <Loader
                type="Grid"
                color="#ffd800"
                height={80}
                width={80}
                timeout={4000}
              />
            </div>
          </Route>
        </Switch>
        <Footer />
      </Router>
    </>
  );
};

export default App;
